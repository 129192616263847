<template>
  <el-drawer
    class="elDrawerDetail"
    :title="comName"
    v-model="drawer"
    :direction="direction"
    destroy-on-close
    size="75%"
  >
    <div class="top_select">
      <div>
        <el-select
          v-model="listQuery.status"
          filterable
          @change="getList()"
          style="width: 75px; margin-left: 0"
          size="small"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option label="正常" :value="1"></el-option>
          <el-option label="非正常" :value="2"></el-option>
        </el-select>
        <el-select
          v-model="listQuery.jcStatus"
          filterable
          @change="getList()"
          style="width: 85px"
          size="small"
        >
          <el-option label="全部" :value="-1"></el-option>
          <el-option label="6万扣除" :value="1"></el-option>
          <el-option label="正常扣除" :value="0"></el-option>
        </el-select>
        <el-input
          placeholder="请输入姓名/证件号码"
          style="width: 170px"
          clearable
          v-model="listQuery.name"
          size="small"
          @keyup.enter="getList"
        />
        <el-button icon="Search" type="primary" size="small" @click="getList()"
          >搜索</el-button
        >
      </div>
      <div>
        <el-dropdown style="margin-right:10px" v-if="$buttonStatus('xc_yg_xz')">
          <el-button type="primary" size="small" icon="CirclePlus">
            新增<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="addPersonnel()">境内人员</el-dropdown-item>
              <el-dropdown-item @click="addOverSeas"
                >境外人员</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown style="margin-right:10px" v-if="$buttonStatus('xc_yg_xz')">
          <el-button type="success" size="small" icon="CirclePlus">
            导入<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="importEmployee">导入境内人员</el-dropdown-item>
              <el-dropdown-item @click="daoruOversea"
                >导入境外人员</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-button
          plain
          type="danger"
          size="small"
          @click="delAllPerson"
          icon="Delete"
          >删除</el-button
        >
        <el-dropdown style="margin-left: 10px" size="small">
          <el-button type="primary" size="small">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-if="$buttonStatus('xc_yg_dc')">
                <div @click="daochu">导出员工</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="$buttonStatus('yg_zc')">
                <div @click="batchSaveNormal">批量修改为正常</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="$buttonStatus('yg_zc')">
                <div @click="batchSaveAbnormal">批量修改为非正常</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="$buttonStatus('yg_zc')">
                <div @click="batchEditJoinDate">批量修改任职受雇日期</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="$buttonStatus('xc_yg_drzxfjkc')">
                <div @click="addallzxkc">导入专项附加扣除</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="$buttonStatus('xc_yg_xgwlwkc')">
                <div @click="batchKcStatus(1)">批量修改为6万扣除</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="$buttonStatus('xc_yg_xgwzckc')">
                <div @click="batchKcStatus(0)">批量修改为正常扣除</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="$buttonStatus('xc_yg_xgwlwkc')">
                <div @click="batchSaveGsJm">批量修改减免费用为0</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="$buttonStatus('xc_yg_xgwlwkc')">
                <div @click="batchIsEmployee(1)">批量修改为是雇员</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="$buttonStatus('xc_yg_xgwlwkc')">
                <div @click="batchIsEmployee(2)">批量修改为非雇员</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="$buttonStatus('xc_yg_dcsbb')">
                <div @click="daochuPerson">导出个人所得税扣缴申报表</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <el-table
      stripe
      :height="contentStyleObj"
      v-loading="loading"
      :data="list"
      border
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" align="center" width="40" />
      <el-table-column align="center" type="index" width="60" label="序号">
      </el-table-column>
      <el-table-column
        label="姓名"
        min-width="80"
        prop="name"
      ></el-table-column>
      <el-table-column
        label="证件类型"
        min-width="100"
        prop="iDType"
      ></el-table-column>
      <el-table-column label="证件号码" min-width="180" prop="iDNo">
      </el-table-column>
      <el-table-column label="手机号" min-width="100" prop="tel">
      </el-table-column>
      <el-table-column label="性别" min-width="60">
        <template #default="{ row }">
          <span v-if="row.sex == 1">男</span>
          <span v-else>女</span>
        </template>
      </el-table-column>
      <el-table-column label="出生日期" min-width="100" prop="birthday">
      </el-table-column>
      <el-table-column label="是否雇员" min-width="80">
        <template #default="{ row }">
          <span v-if="row.isEmployee == 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column label="任职受雇日期" min-width="120" prop="joinDate">
      </el-table-column>
      <el-table-column label="离职日期" min-width="100" prop="departureDate">
      </el-table-column>
      <el-table-column label="是否股东" min-width="80">
        <template #default="{ row }">
          <span v-if="row.stockholder == 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column label="国籍" min-width="80" prop="country">
      </el-table-column>
      <el-table-column label="按6万扣除" min-width="100">
        <template #default="{ row }">
          <span v-if="row.jcStatus == 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column
        label="减免费用为0"
        min-width="110"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <span v-if="row.gsjm0 == 1">个税减免为0</span>
          <span v-else-if="row.gsjm0 == 2">经营所得减免为0</span>
          <span v-else-if="row.gsjm0 == 3">全部减免为0</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column label="人员状态" min-width="90">
        <template #default="{ row }">
          <el-button
            :disabled="!$buttonStatus('yg_zc')"
            type="success"
            v-if="row.status == '1'"
            size="small"
            @click="editStatus(row, 1)"
            plain
            >正常</el-button
          >
          <el-button
            :disabled="!$buttonStatus('yg_zc')"
            type="danger"
            v-else
            size="small"
            @click="editStatus(row, 2)"
            plain
            >非正常</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="120">
        <template #default="{ row }">
          <el-tooltip
            content="编辑"
            placement="top"
            v-if="$buttonStatus('xc_yg_bj')"
            effect="dark"
          >
            <i
              class="iconfont icon-bianji iconStyle"
              @click="editPerson(row)"
            ></i>
          </el-tooltip>
          <el-tooltip
            content="删除"
            placement="top"
            v-if="$buttonStatus('yg_zc')"
            effect="dark"
          >
            <i
              class="iconfont icon-shanchu iconStyle"
              @click="delPerson(row)"
            ></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </el-drawer>
  <addEmployee
    ref="AddEmployee"
    :comId="listQuery.comId"
    :type1="type"
    :period="period"
    @success="getList"
    :from="from"
  />
  <exportEmployee
    ref="ExportEmployee"
    :comId="listQuery.comId"
    :period="period"
    :listQuery="listQuery"
  />
  <changeStatus ref="ChangeStatus" :ids="sels" />
  <batchJoinDate ref="BatchJoinDate" :period="period" @success="getList" />
  <batchGsjm ref="BatchGsjm" @success="getList" />
  <batchDepartureDate ref="BatchDepartureDate" @success="getList" />
  <qzf-import-back
    :hideButton="true"
    @success="getList()"
    ref="importBck"
    from="员工"
    :comId="listQuery.comId"
    :period2="period"
  ></qzf-import-back>
  <qzf-import-back
    :hideButton="true"
    @success="getList()"
    ref="importBckZx"
    from="专项扣除"
    :comId="listQuery.comId"
    :period2="period"
  ></qzf-import-back>
  <qzf-import-back
    :hideButton="true"
    @success="getList()"
    ref="importOversea"
    from="境外人员"
    :comId="listQuery.comId"
    :period2="period"
  ></qzf-import-back>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, nextTick } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { salaryList, delEaEmployee, saveEmployees } from "@/api/salary";
import { exportReport } from "@/api/printSet";
import addEmployee from "../../input/remuneration/components/addEmployee.vue";
import exportEmployee from "@/components/batchEmployeeSet/exportEmployee.vue";
import changeStatus from "../../input/remuneration/components/changeStatus.vue";
import batchJoinDate from "@/components/batchEmployeeSet/batchJoinDate.vue";
import batchGsjm from "@/components/batchEmployeeSet/batchGsjm.vue";
import batchDepartureDate from "@/components/batchEmployeeSet/batchDepartureDate.vue";
const { proxy } = getCurrentInstance();
const drawer = ref(false);
const listQuery = ref({
  page: 1,
  limit: 20,
  comId: 0,
  jcStatus: -1,
  status: 1,
  useStatus:-1,
  disability:-1
});
const comName = ref("");
const loading = ref(false);
const list = ref([]);
const total = ref(0);
const contentStyleObj = ref({});
const AddEmployee = ref();
const type = ref("");
const period = ref("");
const sels = ref([]);
const temp = ref({});
const ExportEmployee = ref();
const ChangeStatus = ref();
const BatchJoinDate = ref();
const BatchGsjm = ref();
const BatchDepartureDate = ref();
const from = ref("")
const importBck = ref();
const importOversea = ref()
const importBckZx = ref();
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(230);
});
const init = (row, date) => {
  period.value = date;
  comName.value = row.name;
  listQuery.value.comId = row.comId;
  drawer.value = true;
  getList();
};
const getList = () => {
  loading.value = true;
  salaryList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      total.value = res.data.data.total;
    }
  });
};
//多选
const handleSelectionChange = (val) => {
  sels.value = val;
};
//新增人员
const addPersonnel = () => {
  type.value = "新增境内人员";
  from.value = 'inside'
  nextTick(()=>{
    AddEmployee.value.openDialog();
  })
};
//新增境外人员
const addOverSeas = () =>{
  type.value = "新增境外人员";
  from.value = 'oversea'
  nextTick(()=>{
    AddEmployee.value.openDialog();
  })
}
//编辑人员
const editPerson = (row) => {
  type.value = "编辑人员";
  from.value = row.iDType == '居民身份证' ? 'inside' : 'oversea'
  nextTick(()=>{
    AddEmployee.value.openDialog(row);
  })
};
//删除人员
const delPerson = (row) => {
  ElMessageBox.confirm(`确定删除${row.name}吗`, "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    delEaEmployee([{ id: row.id }]).then((res) => {
      if (res.data.msg != "success") {
        ElMessageBox.confirm(res.data.msg, "信息", {
          confirmButtonText: "确定",
          type: "warning",
        });
        return;
      }
      ElMessage.success("删除成功");
      getList();
    });
  });
};
//批量删除
const delAllPerson = () => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择人员");
    return;
  }
  ElMessageBox.confirm(`确定删除${sels.value.length}条数据吗`, "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    let ids = sels.value.map((v) => ({
      id: v.id,
    }));
    delEaEmployee(ids).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("删除成功");
        getList();
      } else {
        ElMessage.error("删除失败");
      }
    });
  });
};
//导出
const daochu = () => {
  ExportEmployee.value.init(sels.value);
};
//批量修改为正常
const batchSaveNormal = () => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择人员");
    return;
  }
  sels.value.map((v) => {
    v.departureDate = "";
    v.status = 1;
  });
  saveEmployees(sels.value).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("修改成功");
    }
  });
};
//批量修改为非正常
const batchSaveAbnormal = () => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择人员");
    return;
  }
  ChangeStatus.value.openDialog();
};
//批量修改任职受雇日期
const batchEditJoinDate = () => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择人员");
    return;
  }
  BatchJoinDate.value.init(sels.value);
};
//批量修改扣除
const batchKcStatus = (type) => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择人员");
    return;
  }
  sels.value.map((v) => {
    v.jcStatus = type;
  });
  saveEmployees(sels.value).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("修改成功");
      getList();
    }
  });
};
//批量修改个税减免为0
const batchSaveGsJm = () => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择人员");
    return;
  }
  BatchGsjm.value.init(sels.value);
};
//批量修改是否为雇员
const batchIsEmployee = (type) => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择人员");
    return;
  }
  sels.value.map((v) => {
    v.isEmployee = type;
  });
  saveEmployees(sels.value).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("修改成功");
      getList();
    }
  });
};
//导出个人所得税扣缴申报表
const daochuPerson = () => {
  let param = {
    comIds: [listQuery.value.comId],
    period: period.value,
    bookNames: ["grsdssb"],
  };
  exportReport(param).then((res) => {
    if (res.data.msg == "success") {
      window.open(res.data.data.url);
    }
  });
};
//编辑人员状态
const editStatus = (row, status) => {
  if (status == 2) {
    temp.value = Object.assign({}, row);
    ElMessageBox.confirm("确定恢复该数据？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      temp.value.departureDate = "";
      temp.value.status = 1;
      saveEmployees([temp.value]).then((res) => {
        if (res.data.msg == "success") {
          getList();
          ElMessage.success("修改成功");
        }
      });
    });
  } else {
    ElMessageBox.confirm("确定此员工离职？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      BatchDepartureDate.value.init(row);
    });
  }
};
//导入员工
const importEmployee = () => {
  importBck.value.handImport();
};
//导入境外员工
const daoruOversea = () =>{
  importOversea.value.handImport();
}
//导入专项附加扣除
const addallzxkc = () => {
  importBckZx.value.handImport();
};
defineExpose({
  init,
});
</script>

<style lang="scss" scoped>
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.iconStyle {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
}
.icon-shanchu {
  color: #fe696a;
}
:deep(.el-dropdown-menu__item) {
  display: block;
}
</style>
