<template>
  <div class="app-container">
    <div class="auto_content">
      <div class="auto_content_size">
        <div style="width: 50px">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div class="each_text">
            <div>企业总数</div>
            <span>{{ info.countTotal }}</span
            >家
          </div>
        </div>
        <div class="each" @click="getNewListTotal()">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>本期用户</div>
            <a>
              <span>{{ info.countPeriod }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getWjz">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>异常</div>
            <a>
              <span>{{ info.countErr }}</span
              >家</a
            >
          </div>
        </div>
        <div class="each" @click="getNewListTotal(2)">
          <div class="icon_bg">
            <i class="iconfont icon-shouquanyemian1"></i>
          </div>
          <div class="each_text">
            <div>待确认</div>
            <a>
              <span>{{ info.countDqr }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(1)">
          <div class="icon_bg">
            <i class="iconfont icon-jieyuekehu1"></i>
          </div>
          <div class="each_text">
            <div>已确认</div>
            <a>
              <span>{{ info.countYqr }}</span
              >家
            </a>
          </div>
        </div>
      </div>
      <div class="date_select">
        <qzf-search-period
          v-model:period="listQuery.period"
          :clearable="false"
          style="width: 100px"
          @change="getNewList"
        ></qzf-search-period>
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane name="税款申报">
        <template #label> 税款申报({{ info.countSkSb }}) </template>
      </el-tab-pane>
      <el-tab-pane name="零申报">
        <template #label> 零申报({{ info.countLsb }}) </template>
      </el-tab-pane>
      <div class="top_btns">
        <div class="left_select">
          <el-button
            icon="RefreshRight"
            size="small"
            style="margin-right: 5px"
            @click="getList()"
          ></el-button>
          <el-input
            placeholder="请输入企业名称/编号"
            v-model="listQuery.name"
            style="width: 160px"
            size="small"
            @keyup.enter="getList"
            clearable
          />
          <el-button
            type="primary"
            size="small"
            @click="getList"
            icon="Search"
            style="margin: 0 5px"
          >
            搜索
          </el-button>
          <search @success="getList" @cancel="cancel">
            <el-form
              style="margin: 11px 0px 0px 10px; width: 580px"
              label-width="100px"
              :inline="true"
            >
              <el-form-item label="税局 ：">
                <selectcity
                  v-model:citys="listQuery.districtCode"
                  style="width: 130px"
                ></selectcity>
              </el-form-item>
              <el-form-item label="选择人员 ：">
                <selectuser
                  code="bookkeeping"
                  v-model:userId="listQuery.userId"
                  style="width: 130px"
                ></selectuser>
              </el-form-item>
              <el-form-item label="纳税人类型 ：">
                <selecttaxtype
                  v-model:type="listQuery.type"
                  style="width: 130px"
                ></selecttaxtype>
              </el-form-item>
              <el-form-item label="取数状态 ：">
                <el-radio-group v-model="listQuery.gsMainStatus">
                  <el-radio :label="0">全部</el-radio>
                  <el-radio :label="1">已取数</el-radio>
                  <el-radio :label="2">未取数</el-radio>
                </el-radio-group>
              </el-form-item>
            <!--   <el-form-item label="薪资总额 ："> 
                <el-radio v-model="listQuery.isNot0" :label="0">全部</el-radio>
                <el-radio v-model="listQuery.isNot0" :label="1">薪资总额不为0</el-radio>
              </el-form-item> -->
            </el-form>
          </search>
          <el-radio-group v-model="listQuery.isNot0" style="margin-left:5px" size="small" @change="getList">
            <el-radio-button :label="0" >全部</el-radio-button>
            <el-radio-button :label="1" >有薪酬</el-radio-button>
            <el-radio-button :label="2" >无薪酬</el-radio-button>
          </el-radio-group>
        </div>
        <div>
          <el-button
            @click="confirmAll()"
            type="primary"
            size="small"
            plain
            icon="Checked"
            :disabled="!$buttonStatus('zdjz_xcqr')"
          >
            批量确认
          </el-button>
        </div>
      </div>
      <div class="loudou">
        <el-table
          stripe
          :data="list"
          :height="contentStyleObj"
          border
          @selection-change="handleSelectionChange"
          @filter-change="tableFilterChange"
          class="date-icon"
          v-loading="listLoading"
          @sort-change="sortChange"
        >
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column
            align="center"
            prop="id"
            type="selection"
            width="55"
          />
          <el-table-column
            label="编号"
            align="center"
            prop="sort"
            width="80"
            fixed="left"
            sortable="custom"
          >
            <template #default="scope">
              <TableSortCell :row="scope.row" idKey="comId" />
            </template>
          </el-table-column>
          <el-table-column align="left" fixed label="公司名称" min-width="260">
            <template #default="scope">
              <TagNameCopy
                :row="scope.row"
                idKey="comId"
                :showAuthIcon="false"
              ></TagNameCopy>
            </template>
          </el-table-column>

          <el-table-column prop="district" label="税局" width="65">
            <template #default="scope">
              <span>{{ $cityFilter(scope.row.district) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            prop="districtName"
            label="申报税种"
            min-width="230"
            :filters="categories"
          >
            <template #default="scope">
              <div
                class="div_p"
                v-for="item in scope.row.items"
                :key="item.gsMainId"
              >
                <p style="text-align: left">
                  {{ $tableNameFilter(item.tableName) }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="薪资总额" width="80" prop="amount">
          </el-table-column>
          <el-table-column
            align="left"
            prop="districtName"
            label="系统应补（退）税额"
            min-width="155"
          >
            <template #default="scope">
              <div
                class="div_p"
                v-for="item in scope.row.items"
                :key="item.gsMainId"
              >
                {{ item.tax ? item.tax : 0 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="员工" width="65">
            <template #default="scope">
              <el-button size="small" link @click="checkEmployee(scope.row)" :disabled="!$buttonStatus('zdjz_ygck')"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column align="left" label="取数状态" min-width="100">
            <template #default="scope">
              <div
                class="div_p"
                v-for="item in scope.row.items"
                :key="item.gsMainId"
              >
                <p>{{ item.gsMainStatus == "1" ? "已取数" : "未取数" }}</p>
                <el-button
                  size="small"
                  link
                  :disabled="item.gsMainStatus != '1' || !$buttonStatus('zdjz_xcck')"
                  @click="checkTableDialog(scope.row, item)"
                  v-if="item.tableName == 'gs_gs'"
                  >查看</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="确认状态" width="100">
            <template #default="scope">
              <div class="item_icon">
                <i
                  :class="$filterAutoStatusIcon(scope.row.djqr_salary_status)"
                ></i>
                <span>{{
                  $filterAutoStatusText(scope.row.djqr_salary_status, "qr")
                }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="确认人" width="90">
            <template #default="scope">
              <div v-if="[3, 6].includes(scope.row.djqr_salary_status)">
                {{
                  scope.row.bill_confirm_user_name
                    ? scope.row.bill_confirm_user_name
                    : "系统自动"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="120">
            <template #default="scope">
              <el-button
                @click="confirm(scope.row)"
                size="small"
                link
                :disabled="scope.row.djqr_salary_status != 2 || !$buttonStatus('zdjz_xcqr')"
                >薪酬确认</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <qzf-pagination
          v-show="total > 0"
          :total="total"
          v-model:page="listQuery.page"
          v-model:limit="listQuery.limit"
          @pagination="getList"
        />
      </div>
    </el-tabs>
  </div>
  <salaryDialog ref="SalaryDialog"/>
  <salaryEmployee ref="SalaryEmployee" />
  <abnormalCom ref="AbnormalCom" />
</template>
<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import {
  companyAutoSalaryList,
  confirmAutomaticBill,
  automaticStatSalary,
} from "@/api/automatic";
import { currentAccountPeriod } from "@/utils";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectcity from "@/components/Screening/selectcity";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import salaryDialog from "../../report/tax/components/salaryDialog.vue";
import salaryEmployee from "./salaryEmployee.vue";
import abnormalCom from "./abnormalCom.vue";
const { proxy } = getCurrentInstance();
const activeName = ref("税款申报");
const listQuery = ref({
  page: 1,
  limit: 20,
  taskName: "gs-sb-",
  name: "",
  period: currentAccountPeriod(),
  accountingType: 2,
  taxNames: [],
  from: "个税",
  gsMainStatus: 0,
  isNot0:0
});
const listLoading = ref(false);
const contentStyleObj = ref({});
const total = ref(0);
const list = ref([]);
const sels = ref([]);
const info = ref({
  countSkSb: 0,
  countLsb: 0,
});
const AbnormalCom = ref();
const SalaryDialog = ref();
const SalaryEmployee = ref();
const categories = [
  { text: "综合所得个人所得税预扣预缴申报表", value: "gs_gs" },
  { text: "个人生产经营所得(查账征收)", value: "gs_gs_one" },
];
onMounted(() => {
  getNewList();
  contentStyleObj.value = proxy.$getHeight(340);
});
const getNewList = () => {
  getList();
  getInfo();
};

const getList = () => {
  listLoading.value = true;
  companyAutoSalaryList(listQuery.value).then((res) => {
    listLoading.value = false;
    list.value = res.data.data.list ? res.data.data.list : [];
    total.value = res.data.data.total;
  });
};
const getInfo = () => {
  automaticStatSalary(listQuery.value).then((res) => {
    if (res.data.msg == "success") {
      info.value = res.data.data;
    }
  });
};
//获取异常公司
const getWjz = () => {
  AbnormalCom.value.init(listQuery.value.period);
};

const getNewListTotal = (e) => {
  listQuery.value.salaryStatusTotal = e ? e : null;
  getNewList();
};
const handleClick = (tab) => {
  activeName.value = tab.props.name;
  listQuery.value.accountingType = tab.props.name == "税款申报" ? 2 : 1;
  getList();
};
const tableFilterChange = (filters) => {
  const values = Object.values(filters);
  const value = values[0];
  listQuery.value.taxNames = value;
  getList();
};
const handleSelectionChange = (val) => {
  sels.value = val;
};
const confirm = (row) => {
  proxy
    .$confirm("薪酬是否已确认？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
    .then(() => {
      let param = [
        {
          comId: row.comId,
          period: listQuery.value.period,
          type: "salary",
        },
      ];
      confirmAutomaticBill(param).then((res) => {
        if (res.data.msg == "success") {
          proxy.$qzfMessage("操作成功");
          getList();
          proxy.$bus.emit("confirmStatusUpdate");
        }
      });
    });
};
const confirmAll = () => {
  if (sels.value.length == 0) {
    proxy.$qzfMessage("请选择公司", 1);
    return;
  }
  let fail = sels.value.find((v) => v.djqr_salary_status !== 2);
  if (fail) {
    proxy.$qzfMessage(`${fail.name}不是待确认状态不可确认，请重新选择！`, 1);
    return;
  }
  proxy
    .$confirm("确定要批量确认薪酬吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
    .then(() => {
      let ids = sels.value.map((v) => ({
        comId: v.comId,
        period: listQuery.value.period,
        type: "salary",
      }));
      confirmAutomaticBill(ids).then((res) => {
        if (res.data.msg == "success") {
          proxy.$qzfMessage("操作成功");
          getList();
          proxy.$bus.emit("confirmStatusUpdate");
        }
      });
    });
};
const sortChange = (data) => {
  listQuery.value.desc = data.order == "descending" ? 1 : 0;
  getList();
};
const checkTableDialog = (row, item) => {
  SalaryDialog.value.init(row.comId, listQuery.value.period, row.name);
};
const checkEmployee = (row) => {
  SalaryEmployee.value.init(row, listQuery.value.period);
};
const cancel = () => {
  let originLimit = listQuery.value.limit;
  let originAccountingType = listQuery.value.accountingType;
  listQuery.value = {
    page: 1,
    limit: originLimit,
    taskName: "gs-sb-",
    name: "",
    period: currentAccountPeriod(),
    accountingType: originAccountingType,
    taxNames: [],
    from: "个税",
    gsMainStatus: 0,
    isNot0:0
  };
  getList();
};
</script>

<style lang="scss" scoped>
.top_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .left_select{
    display: flex;
    align-items: center;
  }
}
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.div_p {
  line-height: 28px;
  border-bottom: 1px solid #eee;
  height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  p {
    display: inline-block;
    margin-right: 10px;
  }
  &:last-child {
    border-bottom: none;
  }
}
.item_icon {
  cursor: pointer;
  i {
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 2px;
  }
  p {
    font-size: 13px;
    color: #333;
  }
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: #fff !important;
  width: 10px;
}
.el-table th.el-table__cell > .cell.highlight {
  color: #fff !important;
}
.loudou .el-icon-arrow-down:before {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62e";
  margin-left: 250px;
}
</style>
