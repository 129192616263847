<template>
  <div class="app-container">
    <div class="auto_content">
      <div class="auto_content_size">
        <div style="width: 50px">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div class="each_text">
            <div>企业总数</div>
            <span>{{ info.countTotal }}</span>家
          </div>
        </div>
        <div class="each"  @click="getNewListTotal()">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>本期用户</div>
            <a>
              <span>{{ info.countPeriod }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getWjz">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div
            class="each_text"
          >
            <div>异常</div>
            <a><span>{{ info.countErr }}</span>家</a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(2)">
          <div class="icon_bg">
            <i class="iconfont icon-shouquanyemian1"></i>
          </div>
          <div
            class="each_text"
          >
            <div>待确认</div>
            <a>
              <span>{{ info.countDqr }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(1)">
          <div class="icon_bg">
            <i class="iconfont icon-jieyuekehu1"></i>
          </div>
          <div
            class="each_text"
          >
            <div>已确认</div>
            <a>
              <span>{{ info.countYqr }}</span
              >家
            </a>
          </div>
        </div>
      </div>
      <div class="date_select">
        <qzf-search-period v-model:period="listQuery.period" :clearable="false" style="width:100px" @change="getNewList"></qzf-search-period>
      </div>
    </div>
    <div class="top_select">
      <div class="left_select">
        <el-button icon="RefreshRight" size="small" @click="getList()" style="margin-right:5px"></el-button>
        <el-input size="small" placeholder="请输入公司名称" v-model.trim="listQuery.name" style="width:160px" @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:10px" icon="Search">搜索</el-button>
        <search @success="getList" @cancel="cancel">
        <el-form style="margin:11px 0px 0px 15px;width:300px" label-width="100px" size="small">
          <el-form-item label="纳税人类型 ：">   
            <selecttaxtype v-model:type="listQuery.type" style="width:140px"></selecttaxtype>
          </el-form-item>
          <el-form-item label="税局 ："> 
            <addressList v-model:type="listQuery.address" style="width:140px"></addressList>
          </el-form-item>
         <!--  <el-form-item label="金额 ："> 
            <el-radio v-model="listQuery.isNot0" :label="0">全部</el-radio>
            <el-radio v-model="listQuery.isNot0" :label="1">金额不为0</el-radio>
          </el-form-item> -->
          </el-form>
        </search>
        <el-radio-group v-model="listQuery.isNot0" style="margin-left:5px" size="small" @change="getList">
          <el-radio-button :label="0" >全部</el-radio-button>
          <el-radio-button :label="1" >有单据</el-radio-button>
          <el-radio-button :label="2" >无单据</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-button @click="confirmAll()" type="primary" size="small" plain :disabled="!$buttonStatus('zdjz_fpqr')">
          <el-icon><Checked /></el-icon> <span>批量确认</span>
        </el-button>
      </div>
    </div>
    <el-table stripe :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange" v-loading="loading"  @sort-change="sortChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column label="编号" align="center" width="80" fixed="left" prop="sort" sortable="custom">
        <template #default="scope">
          <TableSortCell :row="scope.row" idKey="comId" />
        </template>
      </el-table-column>
      <el-table-column align="left" prop="name" label="公司名称" min-width="240" fixed="left">
        <template #default="scope">
          <TagNameCopy :row="scope.row" idKey="comId"></TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column align="center" label="地区" width="75">
        <template #default="scope">
          <span >{{$cityFilter(scope.row.district)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="inAmount1" label="进项发票金额" min-width="140">
        <template #default="scope">
          <div>
            <div v-if="scope.row.type == 2">
              <div v-if="scope.row.inAmount1*1">金额：{{parseFloat(scope.row.inAmount1)}}</div>
              <div v-else>金额：0.00</div>
              <div v-if="(scope.row.inAmount3*100 - scope.row.inAmount1*100)/100 != 0">税额：{{((scope.row.inAmount3*100 - scope.row.inAmount1*100)/100).toFixed2()}}</div>
              <div v-else>税额：0.00</div>
            </div>
            <div v-if="scope.row.type == 1">
              <div v-if="scope.row.inAmount3 *1">价税合计：{{Number(scope.row.inAmount3).toFixed2()}}</div>
              <div v-else>价税合计：0.00</div> 
              <div v-if="(scope.row.inAmount3*100 - scope.row.inAmount1*100)/100 != 0">税额：{{((scope.row.inAmount3*100 - scope.row.inAmount1*100)/100).toFixed2()}}</div>
              <div v-else>税额：0.00</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="进项采集任务状态" width="230">
        <template #default="scope">
          <div class="div_p">
            <div class="item_icon">
              <p>
                <el-tooltip :disabled="(scope.row.inErrLog?scope.row.inErrLog:'' + scope.row.inBusinessLog?scope.row.inBusinessLog:'') == ''?true:false" class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.inErrLog?scope.row.inErrLog:'' + scope.row.inBusinessLog?scope.row.inBusinessLog:''"></div>
                  </template>
                  <div>
                    <div style="display:inline-block" v-if="scope.row.inImg && (scope.row.inTaskStatus == 3 && scope.row.inBusinessStatus != 2)" class="demo-image__preview">
                      <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-if="scope.row.inBusinessStatus == 5" @click="open(scope.row, scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-else @click="open(scope.row, scope.$index)"></i>
                    </div>
                    <i v-else :class="$batchSendIconCj(scope.row.inTaskStatus, scope.row.inBusinessStatus)"></i>
                    <span style="padding-left: 0 ;">{{$batchSendCj(scope.row.inTaskStatus, scope.row.inBusinessStatus)}}</span> 
                    <span v-if="scope.row.inAmount2 != '' && scope.row.inBusinessStatus == 2" style="margin-left: 5px;">{{ scope.row.inAmount2 }}张</span>
                    <i class="iconfont icon-wenhao" v-if="(scope.row.inErrLog && scope.row.inErrLog != '成功')|| (scope.row.inBusinessLog && scope.row.inBusinessLog !='成功')" style="font-size:15px;color: red;margin-left: 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <span>{{scope.row.inEndAt}}</span>
            </div>
            <p style="padding-top: 12px;"><el-button @click="declareDetail(scope.row,'in')" size="small" link>采集记录</el-button></p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="outAmount1" label="销项发票金额" min-width="140">
        <template #default="scope">
          <div>
            <div v-if="scope.row.type == 2">
              <div v-if="scope.row.outAmount1*1">金额：{{parseFloat(scope.row.outAmount1)}}</div>
              <div v-else>金额：0.00</div>
              <div v-if="(scope.row.outAmount3*100 - scope.row.outAmount1*100)/100 != 0">税额：{{((scope.row.outAmount3*100 - scope.row.outAmount1*100)/100).toFixed2()}}</div>
              <div v-else>税额：0.00</div>
            </div>
            <div v-if="scope.row.type == 1">
              <div v-if="scope.row.outAmount3*1">价税合计：{{Number(scope.row.outAmount3).toFixed2()}}</div>
              <div v-else>价税合计：0.00</div>
              <div v-if="(scope.row.outAmount3*100 - scope.row.outAmount1*100)/100 != 0">税额：{{((scope.row.outAmount3*100 - scope.row.outAmount1*100)/100).toFixed2()}}</div>
              <div v-else>税额：0.00</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="销项采集任务状态" width="230">
        <template #default="scope">
          <div class="div_p">
            <div class="item_icon">
              <p>
                <el-tooltip :disabled="(scope.row.outErrLog?scope.row.outErrLog:'' + scope.row.outBusinessLog?scope.row.outBusinessLog:'') == ''?true:false" class="item" effect="dark" placement="top-start">        
                  <template #content>
                    <div v-html="scope.row.outErrLog?scope.row.outErrLog:'' + scope.row.outBusinessLog?scope.row.outBusinessLog:''"></div>
                  </template>
                  <div>
                    <div style="display:inline-block" v-if="scope.row.outImg && (scope.row.outTaskStatus == 3 && scope.row.outBusinessStatus != 2)" class="demo-image__preview">
                      <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-if="scope.row.outBusinessStatus == 5" @click="openOut(scope.row, scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-else @click="openOut(scope.row, scope.$index)"></i>
                    </div>
                    <i v-else :class="$batchSendIconCj(scope.row.outTaskStatus, scope.row.outBusinessStatus)"></i>
                    <span style="padding-left: 0 ;"> {{$batchSendCj(scope.row.outTaskStatus, scope.row.outBusinessStatus)}}</span>
                    <span v-if="scope.row.outAmount2 != '' && scope.row.outBusinessStatus == 2" style="margin-left: 5px;">{{ scope.row.outAmount2 }}张</span>
                    <i class="iconfont icon-wenhao" v-if="(scope.row.outErrLog &&scope.row.outErrLog != '成功') || (scope.row.outBusinessLog && scope.row.outBusinessLog !='成功')" style="font-size:15px;color: red;margin-left: 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <span>{{scope.row.outEndAt}}</span>
            </div>
            <p style="padding-top: 12px;"><el-button @click="declareDetail(scope.row,'out')" size="small" link>采集记录</el-button></p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="确认状态" width="100">
        <template #default="scope">
          <div class="item_icon">
            <i :class="$filterAutoStatusIcon(scope.row.djqr_invoice_status)"></i>
            <span>{{$filterAutoStatusText(scope.row.djqr_invoice_status,'qr')}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="确认人" width="90">
        <template #default="scope">
          <div v-if="[3,6].includes(scope.row.djqr_invoice_status)">{{ scope.row.billConfirmUserName ? scope.row.billConfirmUserName : '系统自动' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="180">
        <template #default="scope">
          <el-button @click="handleCheckInvoiceDetail(scope.row)" size="small" link style="padding:5px 0" :disabled="!$buttonStatus('zdjz_fpmx')">发票明细</el-button>
          <el-button @click="confirm(scope.row)" size="small" link :disabled="scope.row.djqr_invoice_status != 2 || !$buttonStatus('zdjz_fpqr')" style="padding:5px 0">发票确认</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
  </div>
  <collectInvoiceDetail ref="collectInvoiceDetail" @success="getList()" />
  <!-- 采集记录 -->
  <declareDetails ref="declareDetails"></declareDetails>
  <sbImgUrl ref="sbImgUrl"></sbImgUrl>
  <abnormalCom ref="abnormalCom" />

</template>

<script>
import selecttaxtype from "@/components/Screening/selecttaxtype";
import {autoCjCompanyList ,confirmAutomaticBill ,automaticStatInvoice} from '@/api/automatic'
import { currentAccountPeriod } from "@/utils";
import collectInvoiceDetail from '../../batch/components/collectInvoiceDetail.vue'
import addressList from "@/components/Screening/address";
import declareDetails from '../../batch/components/declareDetails.vue'
import sbImgUrl from "../../batch/components/sbImgUrl.vue";
import abnormalCom from './abnormalCom.vue'
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
export default {
  name: "invoiceConfirm",
  components: {
    collectInvoiceDetail,
    selecttaxtype,
    addressList,
    declareDetails,
    sbImgUrl,
    abnormalCom,
    TagNameCopy,
    TableSortCell
  },
  props: {},
  data() {
    return {
      listQuery:{
        page:1,
        limit:20,
        period:currentAccountPeriod(),
        autoMatic: 1,
        method:'invoice',
        isNot0:0
      },
      list:[],
      selects:[],
      total:0,
      loading:false,
      contentStyleObj:{},
      info:{}
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(260)
    this.getList()
    this.getInfo()
  },
  methods: {
    getInfo(){
      automaticStatInvoice(this.listQuery).then(res=>{
        if(res.data.msg == 'success'){
          this.info = res.data.data
        }
      })
    },
    getList(){
      this.loading = true
      autoCjCompanyList(this.listQuery).then(res=>{
        this.loading = false
        if(res.data.msg == 'success'){
          this.list = res.data.data.list ? res.data.data.list : []
          this.total = res.data.data.total
        }
      })
    },
    getNewList(){
      this.getList()
      this.getInfo()
    },
    getNewListTotal(e){
      this.listQuery.invoiceStatusTotal = e ? e : null
      this.getNewList()
    },
    handleSelectionChange(e){
      this.selects = e
    },
    //发票明细
    handleCheckInvoiceDetail(row){
      this.$refs.collectInvoiceDetail.init(row,this.listQuery.period,'djqr')
    },
    // 采集记录
    declareDetail(e,type) {
      if(type == 'in'){
        this.$refs.declareDetails.init(e.comId,this.listQuery.period,'tax-cj-inInvoice')
      }else{
        this.$refs.declareDetails.init(e.comId,this.listQuery.period,'tax-cj-outInvoice')
      }
    },
    open(row, index) {
      let imageAll = []
      this.list.map(res => {
        let url = {
          name: res.name,
          url: ""
        }
        url.url = res.inImg ? 'https://file.listensoft.net' + res.inImg + "?" + Math.random(10): ""
        imageAll.push(url)
      })
      this.$refs.sbImgUrl.dialog(imageAll, index)
    },
    openOut(row, index) {
      let imageAll = []
      this.list.map(res => {
        let url = {
          name: res.name,
          url: ""
        }
        url.url = res.outImg ? 'https://file.listensoft.net' + res.outImg + "?" + Math.random(10): ""
        imageAll.push(url)
      })
      this.$refs.sbImgUrl.dialog(imageAll, index)
    },
     // 表格排序
     sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.desc = 1
      }else{
        this.listQuery.desc = 0
      }
      this.getList()
    },
    confirm(row){
      this.$confirm('发票明细是否已确认？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        confirmAutomaticBill([{
          comId:row.comId,
          period:this.listQuery.period,
          type:'invoice'
        }]).then(res=>{
          if(res.data.msg == 'success'){
            this.$qzfMessage('操作成功');
            this.getList()
            this.$bus.emit('confirmStatusUpdate')
          }
        })
      });
    },
    confirmAll(){
      if(this.selects.length == 0){
        this.$qzfMessage('请选择公司',1);
        return
      }
      let str = ""
      this.selects.map(v=>{
        if(v.djqr_invoice_status != 2){
          str = v.name + '不是待确认状态不可确认，请重新选择！'
          return
        }
      })
      if(str){
        this.$qzfMessage(str,1);
        return
      }
      this.$confirm('确定要批量确认发票吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = this.selects.map((v) => ({
          comId: v.comId,
          period:  this.listQuery.period,
          type: "invoice",
        }));
        confirmAutomaticBill(ids).then(res=>{
          if(res.data.msg == 'success'){
            this.$qzfMessage('操作成功');
            this.getList()
            this.$bus.emit('confirmStatusUpdate')
          }
        })
      });
    },
    //重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery= {
        page:1,
        limit:originLimit,
        period:currentAccountPeriod(),
        autoMatic: 1,
        method:"invoice",
        isNot0:0
      }
      this.getList()
    },
    getWjz(){
      this.$refs.abnormalCom.init(this.listQuery.period)
    }
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items:center;
  justify-content: space-between;
  margin-bottom: 5px;
  .left_select{
    display: flex;
    align-items: center;
  }
}
.item_icon{
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 2px;
  }
  p{
    font-size: 13px;
    color: #333;
  }
}
.div_p{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
