<template>
  <div class="app-container">
    <div class="auto_content">
      <div class="auto_content_size">
        <div style="width: 50px">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div class="each_text">
            <div>企业总数</div>
            <span>{{ info.countTotal }}</span>家
          </div>
        </div>
        <div class="each"  @click="getNewListTotal()">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div
            class="each_text"
          >
            <div>本期用户</div>
            <a>
              <span>{{ info.countPeriod }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getWjz">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div
            class="each_text"
          >
            <div>异常</div>
            <a><span>{{ info.countErr }}</span>家</a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(2)">
          <div class="icon_bg">
            <i class="iconfont icon-shouquanyemian1"></i>
          </div>
          <div
            class="each_text"
          >
            <div>待确认</div>
            <a>
              <span>{{ info.countDqr }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(1)">
          <div class="icon_bg">
            <i class="iconfont icon-jieyuekehu1"></i>
          </div>
          <div
            class="each_text"
          >
            <div>已确认</div>
            <a>
              <span>{{ info.countYqr }}</span
              >家
            </a>
          </div>
        </div>
      </div>
      <div class="date_select">
        <qzf-search-period v-model:period="listQuery.period" :clearable="false" style="width:100px" @change="getNewList"></qzf-search-period>
      </div>
    </div>
    <div class="top_select">
      <div class="left_select">
        <el-button icon="RefreshRight" size="small" @click="getList()" style="margin-right:5px"></el-button>
        <el-input size="small" placeholder="请输入公司名称" v-model.trim="listQuery.name" style="width:160px" @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:10px" icon="Search">搜索</el-button>
        <search @success="getList" @cancel="cancel">
        <el-form style="margin:11px 0px 0px 15px;width:300px" label-width="100px" size="small">
          <el-form-item label="纳税人类型 ：">   
            <selecttaxtype v-model:type="listQuery.type" style="width:140px"></selecttaxtype>
          </el-form-item>
          <el-form-item label="税局 ："> 
            <addressList v-model:type="listQuery.address" style="width:140px"></addressList>
          </el-form-item>
          <!-- <el-form-item label="金额 ："> 
            <el-radio v-model="listQuery.isNot0" :label="0">全部</el-radio>
            <el-radio v-model="listQuery.isNot0" :label="1">金额不为0</el-radio>
          </el-form-item> -->
          </el-form>
        </search>
        <el-radio-group v-model="listQuery.isNot0" style="margin-left:5px" size="small" @change="getList">
          <el-radio-button :label="0" >全部</el-radio-button>
          <el-radio-button :label="1" >有银行</el-radio-button>
          <el-radio-button :label="2" >无银行</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-button @click="confirmAll()" type="primary" size="small" plain :disabled="!$buttonStatus('zdjz_yhqr')">
          <el-icon><Checked /></el-icon> <span>批量确认</span>
        </el-button>
      </div>
    </div>
    <el-table stripe :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange" v-loading="loading"  @sort-change="sortChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column label="编号" align="center" width="80" fixed="left" prop="sort" sortable="custom">
        <template #default="scope">
          <TableSortCell :row="scope.row" idKey="comId" />
        </template>
      </el-table-column>
      <el-table-column align="left" prop="name" label="公司名称" min-width="240">
        <template #default="scope">
          <TagNameCopy :row="scope.row" idKey="comId">
            <template v-slot>
              <el-tooltip content="新增银行账户" placement="top" effect="dark" v-if="$buttonStatus('yhcj_xzyh')">
                <i class="iconfont icon-jiahao" style="color:var(--themeColor,#17a2b8);cursor: pointer;margin-left:8px" @click="addComBank(scope.row)">
                </i>
              </el-tooltip>
            </template>
          </TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column align="center" label="地区" width="75">
        <template #default="scope">
          <span >{{$cityFilter(scope.row.district)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="150" >
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.bankItems" :key="item.bankNameId">
            <div class="item_icon" style="width:80px">
              <p>
                <el-tooltip :disabled="!item.errLogBank && !item.businessLogBank" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="item.errLogBank?item.errLogBank:('' + item.businessLogBank?item.businessLogBank:'')"></div>
                  </template>
                  <div>
                    <div style="display:inline-block;" v-if="item.image && (item.taskStatusBank == 3 && item.businessStatusBank != 2)" class="demo-image__preview">
                      <i class="iconfont icon-picture" style="color:red;font-size:16px;"  @click="open(item,index, scope.$index)"></i>
                    </div>
                    <i v-if="!item.image" :class="$batchSendIconCj(item.taskStatusBank, item.businessStatusBank)"></i>
                    <span style="padding-left: 0 ;"><span v-if="item.businessStatusBank == '2' || item.businessStatusBank == '3'">{{ item.reqNoBank?.match('import')?'导入':'采集' }}</span>{{$batchSendCj(item.taskStatusBank, item.businessStatusBank)}}</span> 
                    <i class="iconfont icon-wenhao" v-if="(item.errLogBank && item.errLogBank != '成功')|| (item.businessLogBank && item.businessLogBank !='成功')" style="font-size:15px;color: red;margin-left:4px"></i>
                  </div>
                </el-tooltip>
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="left" prop="count" label="银行交易笔数" min-width="120">
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.bankItems" :key="index">
            <p style="width: 88%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ item.tableNameBank }}
              <el-button @click="daoruBank(item,scope.row)" size="small" link style="padding:5px 0" :disabled="!item.tableNameBank || [3,6,7,8].includes(scope.row.djqr_bank_status) || !$buttonStatus('zdjz_yhdr')">导入</el-button>
            </p>
            <p v-if="item.businessStatusBank == 2"> {{ item.countBank ? item.countBank : 0 }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="left" prop="count" label="银行收款金额" min-width="80">
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.bankItems" :key="index">
           <p>
            <span v-if="item.businessStatusBank == 2">
              {{ item.inAmountBank ? item.inAmountBank : 0 }}
            </span>
          </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="left" prop="count" label="银行支出金额" min-width="80">
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.bankItems" :key="index">
           <p>
            <span v-if="item.businessStatusBank == 2">
              {{ item.outAmountBank ? item.outAmountBank : 0 }}
            </span>
           </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="确认状态" width="100">
        <template #default="scope">
          <div class="item_icon">
            <i :class="$filterAutoStatusIcon(scope.row.djqr_bank_status)"></i>
            <span>{{$filterAutoStatusText(scope.row.djqr_bank_status,'qr')}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="确认人" width="90">
        <template #default="scope">
          <div v-if="[3,6].includes(scope.row.djqr_bank_status)">{{ scope.row.billConfirmUserName ? scope.row.billConfirmUserName : '系统自动' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="205">
        <template #default="scope">
          <el-button @click="handleCheckBankDetail(scope.row)" size="small" link style="padding:5px 0" :disabled="!$buttonStatus('zdjz_yhmx')">银行明细</el-button>
          <el-button @click="confirm(scope.row)" size="small" link :disabled="scope.row.djqr_bank_status != 2 || !$buttonStatus('zdjz_yhqr')" style="padding:5px 0">银行确认</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
  </div>
  <bankDeatils ref="BankDeatils" @success="getList"/>
  <bankImport ref="BankImport" @success="daoruCallBack"/>
  <bankAuth ref="BankAuth" @success="getList"/>
  <abnormalCom ref="AbnormalCom" />
</template>

<script setup>
import { ref,onMounted,getCurrentInstance } from 'vue'
import { ElMessageBox,ElMessage } from 'element-plus'
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import {autoCjCompanyList,confirmAutomaticBill,automaticStatBank} from '@/api/automatic'
import { currentAccountPeriod } from "@/utils";
import bankDeatils from './bankDeatils.vue';
import addressList from "@/components/Screening/address";
import bankImport from './bankImport.vue'
import bankAuth from '@/components/bankAuth/index.vue'
import abnormalCom from './abnormalCom.vue'

const { proxy } = getCurrentInstance()
const listQuery = ref({
  page:1,
  limit:20,
  period:currentAccountPeriod(),
  autoMatic: 1,
  method: 'bank',
  isNot0:0
})
const list = ref([])
const total = ref(0)
const loading = ref(false)
const contentStyleObj = ref({})
const selects = ref([])
const BankDeatils = ref()
const BankImport = ref()
const BankAuth = ref()
const info = ref({})
const AbnormalCom = ref()
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(260)
  getList()
  getInfo()
});
const getList = () =>{
  loading.value = true
  autoCjCompanyList(listQuery.value).then(res=>{
    loading.value = false
    if(res.data.msg == 'success'){
      list.value = res.data.data.list ? res.data.data.list : []
      total.value = res.data.data.total
    }
  })
}
const getWjz = () =>{
  AbnormalCom.value.init(listQuery.value.period)
}
const getInfo = () =>{
  automaticStatBank(listQuery.value).then(res=>{
    if(res.data.msg == 'success'){
      info.value = res.data.data
    }
  })
}
const getNewList = () =>{
  getList()
  getInfo()
}
const getNewListTotal = (e) =>{
  listQuery.value.bankStatusTotal = e ? e : null
  getNewList()
}
//新增银行
const addComBank = (row) =>{
  BankAuth.value.init(row)
}
const handleSelectionChange = (e) =>{
  selects.value = e
}
const confirm = (row) =>{
  ElMessageBox.confirm("银行明细是否已确认？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    let param = [{
      comId:row.comId,
      period:listQuery.value.period,
      type:'bank'
    }]
    confirmAutomaticBill(param).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("操作成功")
        getList();
        proxy.$bus.emit("confirmStatusUpdate");
      }
    });
  });
}
const confirmAll = () =>{
  if(selects.value.length == 0){
    ElMessage.warning("请选择公司")
    return
  }
  let str = ""
  selects.value.map(v=>{
    if(v.djqr_bank_status != 2){
      str = v.name + '不是待确认状态不可确认，请重新选择！'
      return
    }
  })
  if(str){
    ElMessage.warning(str)
    return
  }
  ElMessageBox.confirm("确定要批量确认银行吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    let ids = selects.value.map((v) => ({
      comId: v.comId,
      period: listQuery.value.period,
      type: "bank",
    }));
    confirmAutomaticBill(ids).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("操作成功")
        getList();
        proxy.$bus.emit("confirmStatusUpdate");
      }
    });
  });
}
const daoruBank = (item,row) =>{
  BankImport.value.init(item,row.comId,listQuery.value.period)
}
const handleCheckBankDetail = (row) =>{
  BankDeatils.value.init(row,listQuery.value.period)
}
const daoruCallBack = () =>{
  getList()
  setTimeout(() => {
    getList()
  }, 5000);
}
const sortChange = (data) =>{
  listQuery.value.desc = data.order == 'descending' ? 1 : 0
  getList()
}
const cancel = () => {
  let originLimit = listQuery.value.limit;
  listQuery.value = {
    page:1,
    limit:originLimit,
    period:currentAccountPeriod(),
    autoMatic: 1,
    method: 'bank',
    isNot0:0
  };
  getList();
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items:center;
  justify-content: space-between;
  margin-bottom: 5px;
  .left_select{
    display: flex;
    align-items:center;
  }
}
.item_icon{
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 2px;
  }
  p{
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
.div_p{
  display: flex;
  align-items: center;
  justify-content: space-between;
  p{
    height: 24px;
    line-height: 24px;
  }
}
</style>
