<template>
  <div>
    <qzf-tabs v-model:value="value">
      <qzf-tabs-item label="发票确认" name="1" :cache="false">
        <invoiceConfirm ref="invoiceConfirm"></invoiceConfirm>
      </qzf-tabs-item>
      <qzf-tabs-item label="银行确认" name="2" :cache="false">
        <bankConfirm ref="bankConfirm"></bankConfirm>
      </qzf-tabs-item>
      <qzf-tabs-item label="薪酬确认" name="3" :cache="false">
        <salaryConfirm ref="salaryConfirm"></salaryConfirm>
      </qzf-tabs-item>
    </qzf-tabs>
  </div>
</template>

<script>
import bankConfirm from "./components/bankConfirm.vue";
import invoiceConfirm from "./components/invoiceConfirm.vue";
import salaryConfirm from "./components/salaryConfirm.vue";
export default {
  name: "billConfirm",
  components: { bankConfirm, invoiceConfirm,salaryConfirm },
  data() {
    return {
      value: "1",
    };
  },
  mounted() {
    this.initBus();
  },

  methods: {
    initBus(){
      this.$bus.off("confirmStatusUpdate")
      this.$bus.on("confirmStatusUpdate", (val) => {
        setTimeout(()=>{
          this.$refs.invoiceConfirm.getList()
          this.$refs.bankConfirm.getList()
          this.$refs.salaryConfirm.getList()
        })
      });
    },
  },
};
</script>

<style></style>
