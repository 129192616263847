<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    v-model="dialogFormVisible"
    width="600px"
    title="导入"
  >
    <el-form size="small" label-position="right" label-width="5px">
      <el-form-item>
        <el-input
          style="width: 70%; margin-right: 10px"
          v-model="path"
        ></el-input>
        <input
          size="small"
          ref="excelUploadInput"
          style="display: none"
          type="file"
          accept=".xlsx, .xls, .xml"
          @change="handleClick"
        />
        <el-button type="primary" @click="handleUpload()">请选择文件</el-button>
      </el-form-item>
      <el-form-item>
        <el-radio-group v-model="mubanType">
          <el-radio label="2">通用导入</el-radio>
          <el-radio label="1">模板导入</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="mubanType == '1'">
        <el-select
          v-model="type"
          placeholder="请选择"
          clearable
          @change="mubanChange"
          filterable
        >
          <el-option
            v-for="(item, index) in list"
            :key="index"
            :label="item.type"
            :value="item.type"
          ></el-option>
        </el-select>
        <el-button type="primary" @click="downLoadUrl">点击下载模板</el-button>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取消</el-button
        >
        <el-button
          :loading="loading"
          type="primary"
          @click="uploadExcel()"
          size="small"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <otherBank
    ref="bankTy"
    :bankName="bankInfo.tableNameBank"
    :bankId="bankInfo.bankNameId"
    @success="tySuccess"
  ></otherBank>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { importTemplate, importExcel } from "@/api/import";
import { autoBankImportTem } from "@/api/automatic";
import { ElMessage, ElMessageBox } from "element-plus";
import otherBank from "@/components/import/son/otherBank";
const emit = defineEmits();
const importId = ref(0);
const importPeriod = ref("");
const bankInfo = ref({});
const list = ref([]);
const type = ref("");
const mubanType = ref("2");
const excelUrl = ref("");
const path = ref("");
const rawFile = ref("");
const dialogFormVisible = ref(false);
const excelUploadInput = ref();
const loading = ref(false);
const bankTy = ref();
onMounted(() => {
  importTemplate({ source: "银行" }).then((res) => {
    list.value = res.data.data.list ? res.data.data.list : [];
    type.value = list.value[0].type;
    excelUrl.value = list.value[0].excelUrl;
  });
});
const init = (item, comId, period) => {
  dialogFormVisible.value = true;
  path.value = "";
  bankInfo.value = item;
  importId.value = comId;
  importPeriod.value = period;
};
const handleUpload = () => {
  excelUploadInput.value.click();
};
const handleClick = (e) => {
  const files = e.target.files;
  rawFile.value = files[0]; // only use files[0]
  path.value = e.target.value; //路径
};
const uploadExcel = () => {
  if (!type.value && mubanType.value == "1") {
    ElMessage.warning("请选择模板");
    return;
  }
  if (!rawFile.value) {
    ElMessage.warning("请选择文件");
    return;
  }
  loading.value = true;
  const param = new FormData(); // 创建form对象
  param.append("file", rawFile.value, rawFile.value.name); // 单个图片 ，多个用循环 append 添加
  param.append("type", type.value);
  param.append("from", "银行");
  param.append("id", bankInfo.value.bankNameId);
  param.append("comId", importId.value);
  param.append("period", importPeriod.value);
  param.append("periodType", "1");
  if (mubanType.value == "2") {
    importExcel(param).then((res) => {
      loading.value = false;
      if (res.data.msg == "success") {
        ElMessage.success("导入成功");
        let bankList = res.data.data.data[0].data;
        let max = 0;
        bankList.map((v) => {
          if (v.length > max) {
            max = v.length;
          }
        });
        bankList.map((v) => {
          for (let i = 0; i < max; i++) {
            v.push("");
          }
        });
        bankTy.value.dialogFormVisible = true;
        bankTy.value.init(bankList, "1", importId.value, importPeriod.value); 
      }
    });
  } else {
    autoBankImportTem(param).then((res) => {
      loading.value = false;
      if (res.data.msg == "success") {
        ElMessage.success("任务发起成功");
        dialogFormVisible.value = false;
        emit("success");
      }
    });
  }
};
const tySuccess = () =>{
  dialogFormVisible.value = false;
  emit("success");
}
const downLoadUrl = () => {
  if (excelUrl.value && mubanType.value == "1") {
    window.open(excelUrl.value);
  } else {
    ElMessage.warning("请联系客服处理！");
  }
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss"></style>
